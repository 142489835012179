import React from 'react';
import './Sidebar.scss';
import MenuTitle from './MenuTitle';
import MenuItem from './MenuItem';
import Pages from '../../../constants/pages';
import SimpleBar from 'simplebar-react'
import Restricted from '../../Permissions/Restricted';
import Permissions from '../../../constants/Permissions';
import CustomMetisMenu from './CustomMetisMenu';
import sampIcon from '../../../assets/images/samp/samp_icon.jpg'
import rageIcon from '../../../assets/images/rage/rage_icon.png'

const Sidebar = () => {

    return ( 
        <div className="sidebar vertical-menu">
            <SimpleBar 
                style={{
                    maxHeight: '100%'
                }}
            >
                <div id="sidebar-menu">
                    <CustomMetisMenu 
                        id="side-menu"
                        className="metismenu list-unstyled" 
                    >
                        <MenuTitle title={'Opšte'} />
                        <MenuItem title={'Početna'} icon={<i className="bx bx-home-circle"></i>} linkTo={Pages.HOME_PAGE} />
                        
                        <Restricted to={Permissions.ADMINISTRATOR}>
                            <MenuItem title={'Lista korisnika'} icon={<i className="bx bx-user-circle"></i>} linkTo={Pages.USER_MANAGEMENT} />
                            <MenuItem title={'Permisije'} icon={<i className="bx bx-user-check"></i>} linkTo={Pages.PERMISSION_MANAGEMENT} />
                            <MenuItem title={'Konfiguracija'} icon={<i className="fas fa-cogs"></i>} linkTo={Pages.CONFIGURATION} />
                        </Restricted>

                        <MenuTitle title={'Serveri'} />
                        <Restricted to={Permissions.RAGE_ADMIN}>
                            <MenuItem title={'RageMP'} icon={<i><img src={rageIcon} alt='' width={'20px'} height={'20px'}/></i>}>
                                <MenuItem title={'Staff'} icon={<i className="fas fa-user-shield"></i>}>
                                    <MenuItem title={'Početna'} linkTo={Pages.RAGE_STAFF_PAGE} />
                                    <MenuItem title={'Logovi'} linkTo={Pages.RAGE_STAFF_LOGS_PAGE} />
                                </MenuItem>
                                <MenuItem title={'Organizacije'} icon={<i className="fas fa-sitemap"></i>}>
                                    <MenuItem title={'Organi reda'} linkTo={Pages.RAGE_ORG_LAW_ENFORCEMENT_PAGE} />
                                    <MenuItem title={'Kriminalne organizacije'} linkTo={Pages.RAGE_ORG_CRIMINALS_PAGE} />
                                </MenuItem>
                                <Restricted to={Permissions.ADMINISTRATOR}>
                                    <MenuItem title={'Ekonomija'} icon={<i className="fas fa-chart-line"></i>} linkTo={Pages.RAGE_ECONOMY_PAGE} />
                                    <MenuItem title={'Novac'} icon={<i className="fas fa-file-invoice-dollar"></i>} linkTo={Pages.RAGE_MONEY_PAGE} />
                                    <MenuItem title={'Donatori'} icon={<i className="fas fa-chess-king"></i>} linkTo={Pages.RAGE_DONATOR_PAGE} />
                                    <MenuItem title={'Igrači'} icon={<i className="fas fa-users"></i>} linkTo={Pages.RAGE_PLAYERS_PAGE} />
                                    <MenuItem title={'Banovi'} icon={<i className="fas fa-ban"></i>} linkTo={Pages.RAGE_BANS_PAGE} />
                                    <MenuItem title={'Promocije'} icon={<i className="bx bxs-megaphone"></i>} linkTo={Pages.RAGE_PROMOTIONS_PAGE} />
                                </Restricted>
                                <MenuItem title={'Logs'} icon={<i className="bx bx-package"></i>}>
                                    <Restricted to={Permissions.ADMINISTRATOR}>
                                        <MenuItem title={'CEF'} linkTo={Pages.RAGE_CEF_LOGS_PAGE} />
                                        <MenuItem title={'Client'} linkTo={Pages.RAGE_CLIENT_LOGS_PAGE} />
                                    </Restricted>
                                    <MenuItem title={'Connection Logs'} linkTo={Pages.RAGE_CONN_LOGS_PAGE} />
                                    <MenuItem title={'Death Logs'} linkTo={Pages.RAGE_DEATH_LOGS_PAGE} />
                                    <MenuItem title={'Arrest Logs'} linkTo={Pages.RAGE_ARREST_LOGS_PAGE} />
                                    <MenuItem title={'Fraction Logs'} linkTo={Pages.RAGE_FRACTION_LOGS_PAGE} />
                                    <MenuItem title={'Stock Logs'} linkTo={Pages.RAGE_STOCK_LOGS_PAGE} />
                                    <MenuItem title={'Kupoprodaja Logs'} linkTo={Pages.RAGE_KUPOPRODAJA_LOGS_PAGE} />
                                </MenuItem> 
                            </MenuItem>
                        </Restricted>

                        <Restricted to={Permissions.SAMP_ADMIN}>
                            <MenuItem title={'SAMP #1 - Vinewood'} icon={<i><img src={sampIcon} alt='' width={'20px'} height={'20px'}/></i>}>
                                <MenuItem title={'Poslovi'} icon={<i className="fas fa-truck"></i>} linkTo={Pages.SAMP_S1_JOBS_PAGE} />
                                <MenuItem title={'Lažni nalozi'} icon={<i className="fas fa-users"></i>} linkTo={Pages.SAMP_S1_FAKE_ACCOUNTS_PAGE} />
                                <MenuItem title={'Čiteri'} icon={<i className="fas fa-user-secret"></i>} linkTo={Pages.SAMP_S1_CHEATERS_PAGE} />
                                <MenuItem title={'Promene imena'} icon={<i className="bx bx-file"></i>} linkTo={Pages.SAMP_S1_CHANGE_NAMES_PAGE} />
                            </MenuItem>

                            <MenuItem title={'SAMP #2 - Downtown'} icon={<i><img src={sampIcon} alt='' width={'20px'} height={'20px'}/></i>}>
                                <MenuItem title={'Poslovi'} icon={<i className="fas fa-truck"></i>} linkTo={Pages.SAMP_S2_JOBS_PAGE} />
                                <MenuItem title={'Lažni nalozi'} icon={<i className="fas fa-users"></i>} linkTo={Pages.SAMP_S2_FAKE_ACCOUNTS_PAGE} />
                                <MenuItem title={'Čiteri'} icon={<i className="fas fa-user-secret"></i>} linkTo={Pages.SAMP_S2_CHEATERS_PAGE} />
                                <MenuItem title={'Promene imena'} icon={<i className="bx bx-file"></i>} linkTo={Pages.SAMP_S2_CHANGE_NAMES_PAGE} />
                            </MenuItem>
                        </Restricted>

                        <MenuTitle title={'Ostalo'} />
                        <Restricted to={Permissions.ADMINISTRATOR}>
                            <MenuItem title={'Društvene mreže'} icon={<i className="fa fa-user-friends"></i>} 
                                linkTo={Pages.SOCIAL_NETWORKS_PAGE} />
                            <MenuItem title={'Donatori'} icon={<i className="fas fa-chess-king"></i>} 
                                linkTo={Pages.ORDERS_PAGE} />
                        </Restricted>
                    </CustomMetisMenu>
                </div>
            </SimpleBar>
            
        </div>
    );
}
 
export default Sidebar;