import { useState } from 'react';
import PageTitle from '../../../components/common/PageTitle';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDate } from '../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../constants/rageLogTables';
import { formatMoney } from '../../../helpers/moneyFormatter';

const RageEconomyPage = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 115, valueFormatter: (params) => formatDate(params.value) },
        { field: 'jobs', headerName: 'Jobs', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'sellOnMarket', headerName: 'Sell On Market', type: 'number', flex: 1, minWidth: 130, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'boughtWithBsc', headerName: 'Bought With BSC', type: 'number', flex: 1, minWidth: 150, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'casino', headerName: 'Casino', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'luckyWheel', headerName: 'Lucky Wheel', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'dailyRewards', headerName: 'Daily Rewards', type: 'number', flex: 1, minWidth: 120, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'dailyAttendanceRewards', headerName: 'Daily Attend. Rew.', description: 'Daily Attendance Rewards', type: 'number', flex: 1, minWidth: 150, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'cases', headerName: 'Cases', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'givemoneyCmd', headerName: 'Givemoney CMD', type: 'number', flex: 1, minWidth: 130, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'worldActivities', headerName: 'World Activities', type: 'number', flex: 1, minWidth: 130, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'payday', headerName: 'Payday', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'missions', headerName: 'Missions', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'businessesProfit', headerName: 'Businesses Profit', type: 'number', flex: 1, minWidth: 150, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'bankRobberies', headerName: 'Bank Robberies', type: 'number', flex: 1, minWidth: 150, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'businessRobberies', headerName: 'Biz. Robberies', type: 'number', flex: 1, minWidth: 130, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'fractionActivities', headerName: 'Fraction Activities', type: 'number', flex: 1, minWidth: 150, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'lotto', headerName: 'Lotto', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'other', headerName: 'Other', type: 'number', flex: 1, minWidth: 115, valueFormatter: (params) => formatMoney(params.value) }
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Ekonomija'/>

            <DataTable 
                title={'Izvori stvaranja novca, po datumu'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.Economy)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
                density='compact'
                initialPageSize={15}
            />
        </> 
    );
}
 
export default RageEconomyPage;