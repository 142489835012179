import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { GROUPING_TYPE } from '../../constants/grouping';

const TimeGroupingFilterHeader = ({ filters, setFilters, customDaysOptions, minGroupingType }) => {

    const options = [ 3, 7, 14, 30, 90, 180, 360, 540 ].concat(customDaysOptions ?? []).sort((a, b) => a - b);

    const getGroupingType = (selectedDays) => {

        const currGroupingType = filters.grouping;

        if(selectedDays < 30 && currGroupingType > GROUPING_TYPE.Days)
            return GROUPING_TYPE.Days;
        else if(selectedDays > 360 && currGroupingType < GROUPING_TYPE.Months)
            return GROUPING_TYPE.Months;
        else if(selectedDays > 14 && currGroupingType < GROUPING_TYPE.Days)
            return GROUPING_TYPE.Days;
        else if(selectedDays > 7 && currGroupingType < GROUPING_TYPE.Hours)
            return GROUPING_TYPE.Hours;
        else
            return currGroupingType
    }

    const getDays = (selectedGroupingType) =>
    {
        const currDays = filters.days;
        if(selectedGroupingType === GROUPING_TYPE.Minutes && currDays > 7)
            return 7;
        else if(selectedGroupingType === GROUPING_TYPE.Hours && currDays > 14)
            return 14;
        else if(selectedGroupingType === GROUPING_TYPE.Days && currDays > 360)
            return 360;
        else if(selectedGroupingType === GROUPING_TYPE.Months && currDays < 30)
            return 30;
        else
            return currDays;
    }

    const getGroupingLabel = (groupingType) => {
        switch(groupingType)
        {
            case GROUPING_TYPE.Minutes:
                return 'Po minutima';
            case GROUPING_TYPE.Hours:
                return 'Po satima';
            case GROUPING_TYPE.Days:
                return 'Po danima';
            case GROUPING_TYPE.Months:
                return 'Po mesecima';
            default:
                return 'UNDEFINED';
        }
    }

    return (  
        <Grid container display={'flex'} gap={'20px'} justifyContent={'flex-end'} width={'100%'} marginBottom={'5px'} paddingRight={'5px'}>
            <Grid item>
                <FormControl style={{ minWidth: '100px'}}>
                    <InputLabel id="grouping-label">Grupisanje</InputLabel>
                    <Select
                        id="grouping"
                        value={filters.grouping}
                        label="Grupisanje"
                        onChange={(e) => setFilters({ ...filters, grouping: e.target.value, days: getDays(e.target.value) })}
                        size='small'
                    >
                        {
                            Object.keys(GROUPING_TYPE)
                            .filter(key => !minGroupingType || GROUPING_TYPE[key] >= minGroupingType)
                            .map((key, index) => {
                                return (
                                    <MenuItem key={index} value={GROUPING_TYPE[key]}>{getGroupingLabel(GROUPING_TYPE[key])}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl style={{ minWidth: '100px'}}>
                    <InputLabel id="days-label">Dani</InputLabel>
                    <Select
                        id="days"
                        value={filters.days}
                        label="Dani"
                        onChange={(e) => setFilters({ ...filters, days: e.target.value, grouping: getGroupingType(e.target.value) })}
                        size='small'
                    >
                        {
                            options.map((value, index) => {
                                return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
 
export default TimeGroupingFilterHeader;