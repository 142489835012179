const ApiRoutes = {
    Auth: {
        Login: '/Auth/Login',
        RefreshToken: '/Auth/RefreshToken',
        Register: '/Auth/Register'
    },
    Home: {
        ForceCacheJob: '/Home/ForceCacheJob',
        GetLastCacheUpdate: '/Home/GetLastCacheUpdate',
        GetSAMPServerData: '/Home/GetSAMPServerData',
        GetRageServerData: '/Home/GetRageServerData',
        GetAlerts: '/Home/GetAlerts',
        GetUserPermissions: '/Home/GetUserPermissions'
    },
    Notifications: {
        GetNotifications: '/Notifications/GetNotifications'
    },
    Users: {
        GetUsers: '/Users/GetUsers',
        GetAllRolesWithUserHasFlag: '/Users/GetAllRolesWithUserHasFlag',
        DeleteUser: '/Users/DeleteUser',
        SetUserApprovedStatus: '/Users/SetUserApprovedStatus',
        UpdateUserRoles: '/Users/UpdateUserRoles',
    },
    Permissions: {
        GetAllPermissions: '/Permissions/GetAllPermissions',
        CreatePermission: '/Permissions/CreatePermission',
        DeletePermission: '/Permissions/DeletePermission',
        SetParentToPermission: '/Permissions/SetParentToPermission',
    },
    Samp: {
        GetJobsAbuse: '/Samp/GetJobsAbuse',
        GetFakeAccounts: '/Samp/GetFakeAccounts',
        GetCheaters: '/Samp/GetCheaters',
        GetPlayerNamesChanges: '/Samp/GetPlayerNamesChanges',
    },
    Configuration: {
        GetPanelConfiguration: '/Configuration/GetPanelConfiguration',
        SwitchRegistrationsState: '/Configuration/SwitchRegistrationsState',
        GetSampConfiguration: '/Configuration/GetSampConfiguration',
        CheckDbConnectivity: '/Configuration/CheckDbConnectivity',
        GetRageConfiguration: '/Configuration/GetRageConfiguration',
        SwitchRageServerState: '/Configuration/SwitchRageServerState',
    },
    Rage: {
        GetBusinessesMoney: '/Rage/GetBusinessesMoney',
        GetRageMoneyPage: '/Rage/GetRageMoneyPage',
        GetRageMoneyByDays: '/Rage/GetRageMoneyByDays',
        GetRageMoneyDiffByTime: '/Rage/GetRageMoneyDiffByTime',
        GetWealthiestPlayers: '/Rage/GetWealthiestPlayers',

        GetDonatorsPage: '/Rage/GetDonatorsPage',
        GetBscByDays: '/Rage/GetBscByDays',
        GetBscDiffByTime: '/Rage/GetBscDiffByTime',
        GetMostBscAccounts: '/Rage/GetMostBscAccounts',

        GetPlayersPage: '/Rage/GetPlayersPage',
        GetPlayersCount: '/Rage/GetPlayersCount',
        GetRegistrationsCount: '/Rage/GetRegistrationsCount',
        GetTopLevelPlayers: '/Rage/GetTopLevelPlayers',
        GetPlayersCountLast24h: '/Rage/GetPlayersCountLast24h',

        GetTopPromotions: '/Rage/GetTopPromotions',
        GetPromotionsByDays: '/Rage/GetPromotionsByDays',
        GetTopPromotersWeekly: '/Rage/GetTopPromotersWeekly',

        GetLogs: '/Rage/GetLogs',
        ResolveLogProblem: '/Rage/ResolveLogProblem',
        GetLogTable: '/Rage/GetLogTable',

        GetStaffPage: '/Rage/GetStaffPage',
        GetReportsDaily: '/Rage/GetReportsDaily',
        GetReportsAvgAnswerTimeDaily: '/Rage/GetReportsAvgAnswerTimeDaily',
        GetAdminsStats: '/Rage/GetAdminsStats',
        GetPunishments: '/Rage/GetPunishments',
        GetReports: '/Rage/GetReports',

        GetPDCallLogs: '/Rage/GetPDCallLogs',
        GetBankRobberyLogs: '/Rage/GetBankRobberyLogs',
        GetTicketLogs: '/Rage/GetTicketLogs',
        
        GetBansPage: '/Rage/GetBansPage',
    },
    SocialNetworks: {
        GetDiscordMembersCountHistory: '/SocialNetworks/GetDiscordMembersCountHistory',
        GetYoutubeSubscribersCountHistory: '/SocialNetworks/GetYoutubeSubscribersCountHistory',
        GetInstagramFollowersCountHistory: '/SocialNetworks/GetInstagramFollowersCountHistory',
    },
    Orders: {
        GetOrdersPage: '/Orders/GetOrdersPage',
        GetOrdersByDays: '/Orders/GetOrdersByDays',
        GetRevenueByDays: '/Orders/GetRevenueByDays',
        GetOrdersNumByDaysByPaymentMethod: '/Orders/GetOrdersNumByDaysByPaymentMethod',
        GetRevenueByDaysByPaymentMethod: '/Orders/GetRevenueByDaysByPaymentMethod',
        GetAllOrders: '/Orders/GetAllOrders',
        GetAllTransactions: '/Orders/GetAllTransactions',
    },
    Data: {
        GetData: '/Data/GetData'
    }
}

export default ApiRoutes;