import { GROUPING_TYPE } from '../constants/grouping'

export const getTooltipByGroupingFilter = (addGroupingFilterHeader, filters) => {
    if(!addGroupingFilterHeader)
        return { }

    if(filters.grouping === GROUPING_TYPE.Months)
        return { x: { format: 'MMM yyyy.' }}
    else if(filters.grouping === GROUPING_TYPE.Days)
        return { x: { format: 'dd. MMM yyyy.' }}
    else if(filters.grouping === GROUPING_TYPE.Hours)
        return { x: { format: 'dd. MMM yyyy. HH\\h' }}
    else
        return { x: { format: 'dd. MMM yyyy. HH:mm' }}
}