import ApiRoutes from '../constants/ApiRoutes';
import { getRequest } from './Api';


export const getBusinessesMoney = () => getRequest(ApiRoutes.Rage.GetBusinessesMoney);
export const getRageMoneyPage = () => getRequest(ApiRoutes.Rage.GetRageMoneyPage);
export const getRageMoneyByDays = () => getRequest(ApiRoutes.Rage.GetRageMoneyByDays);
export const getRageMoneyDiffByTime = () => getRequest(ApiRoutes.Rage.GetRageMoneyDiffByTime);
export const getWealthiestPlayers = () => getRequest(ApiRoutes.Rage.GetWealthiestPlayers);

export const getDonatorsPage = () => getRequest(ApiRoutes.Rage.GetDonatorsPage);
export const getBscByDays = () => getRequest(ApiRoutes.Rage.GetBscByDays);
export const getBscDiffByTime = () => getRequest(ApiRoutes.Rage.GetBscDiffByTime);
export const getMostBscAccounts = () => getRequest(ApiRoutes.Rage.GetMostBscAccounts);

export const getPlayersPage = () => getRequest(ApiRoutes.Rage.GetPlayersPage);
export const getPlayersCount = (filters) => getRequest(ApiRoutes.Rage.GetPlayersCount, filters);
export const getRegistrationsCount = (filters) => getRequest(ApiRoutes.Rage.GetRegistrationsCount, filters);
export const getTopLevelPlayers = () => getRequest(ApiRoutes.Rage.GetTopLevelPlayers);
export const getPlayersCountLast24h = () => getRequest(ApiRoutes.Rage.GetPlayersCountLast24h);

export const getTopPromotions = () => getRequest(ApiRoutes.Rage.GetTopPromotions);
export const getPromotionsByDays = () => getRequest(ApiRoutes.Rage.GetPromotionsByDays);
export const getTopPromotersWeekly = (filters) => getRequest(ApiRoutes.Rage.GetTopPromotersWeekly, filters);

export const getRageLogs = (logsSide) => getRequest(ApiRoutes.Rage.GetLogs + `?logsSide=${logsSide}`);
export const resolveLogProblem = (logsSide, problemId) => getRequest(ApiRoutes.Rage.ResolveLogProblem + `?logsSide=${logsSide}&problemId=${problemId}`);
export const getRageLogTable = (tableId) => getRequest(ApiRoutes.Rage.GetLogTable + `?tableId=${tableId}`);

export const getRageStaffPage = () => getRequest(ApiRoutes.Rage.GetStaffPage);
export const getReportsDaily = () => getRequest(ApiRoutes.Rage.GetReportsDaily);
export const getReportsAvgAnswerTimeDaily = () => getRequest(ApiRoutes.Rage.GetReportsAvgAnswerTimeDaily);
export const getAdminsStats = () => getRequest(ApiRoutes.Rage.GetAdminsStats);
export const getPunishments = () => getRequest(ApiRoutes.Rage.GetPunishments);
export const getReports = () => getRequest(ApiRoutes.Rage.GetReports);

export const getPDCallLogs = () => getRequest(ApiRoutes.Rage.GetPDCallLogs);
export const getBankRobberyLogs = () => getRequest(ApiRoutes.Rage.GetBankRobberyLogs);
export const getTicketLogs = () => getRequest(ApiRoutes.Rage.GetTicketLogs);

export const getRageBansPage = () => getRequest(ApiRoutes.Rage.GetBansPage);